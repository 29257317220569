import _, { isEmpty, random, isNil } from 'lodash';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import JSEncrypt from 'jsencrypt';
import { toast } from 'react-toastify';

import callApi, { API_URL, WEBUTILS_URL, OLYMPUS_URL } from './apiCaller';
import {
    BASE_PATH_INVESTEE_APPROVED_ACTIVE,
    LAKH,
    TIME_ENDS,
    ROUTE,
    API_STATUS,
    PRODUCTION,
    USER_SEGMENT_TYPE,
    USER_TYPE_INVESTEE,
    DAYJS_DATE_FORMAT,
    CRORE,
    ROUND_OFF,
    WIDGETS,
    investor_mfa_status,
    SHOW_NOTIFICATION_STATUS,
    PAYMENT_STATUS,
    EXPORT_CSV,
    DEAL_TYPES,
    DEFAULT_MORAT_VAL_MONTHS,
    ONBOARDING_STATUS,
    ADD_ON_LIMIT_CONST,
    FLOW_ID,
} from '../enums/appEnums';
import { getRepaymentFrequencyAndGapValue } from './calculateTermUtil';
import sources from '../constants/sources.json';
import { ToWords } from 'to-words';
import { Mixpanel } from './mixpanel';
import { captureMessagesViaErrorLogger } from './errorLogger';
import {
    INVESTEE_BASE_PATH,
    INVESTEE_ROUTES,
    INVESTOR_BASE_PATH,
    INVESTOR_ROUTES,
} from '../RoutesConst';
import storage from './storageService';
import {
    COMPLETED,
    FILLING,
} from '../modules/Investee/pages/InvesteeOnboardingNew/components/BankAccounts/constants/consts';
import {
    callRegenerateCounterSigningLink,
    downloadFileHelper,
    getPresignedUrl,
    getTdsFilingReportDownload,
} from '../modules/Investee/common/apiHelpers';
import moment from 'moment';
import { ROUTES_MAP, SIDEBAR_LABELS } from '../components/Sidebar/consts/consts';
import { blockWidgetStep, CATEGORIES } from '../modules/Investee/components/Widget/utils';
import { CATEGORIES as UPLOAD_CATEGORIES } from '../modules/Investee/components/UploadDataJourney/utils/UploadCard/utils/uploadDocUtils';
import {
    DEAL_CHEST_WEBSITE,
    DECENTRO_BANK_FAILURE,
    PENNY_DROP_VERIFIED,
    genericEmails,
} from '../constants/consts';
import { MONTHLY_VIEW_DATA_TYPES } from '../modules/Investee/components/UploadDataJourney/utils/constants';
import { Switch } from '@mui/material';
import { ONBOARDING } from '../modules/Investee/components/UploadDataJourney/components/BankAccounts/components/ODFD/ODFD';

const XLSX = require('xlsx');
const { PENDING_VERIFICATION_DEALS, COMPLETED_VERIFICATION_DEALS, NEW_DEALS, EVALUATED_DEALS } =
    DEAL_TYPES;

dayjs.extend(UTC);
dayjs.extend(customParseFormat);

export function showNotification(type, msg, customId = 'custom-id-yes', autoCloseTime) {
    // toast.dismiss();
    type = type?.toLowerCase();
    switch (type) {
        case 'success':
            toast.success(
                ({ closeToast }) => (
                    <div style={{ position: 'relative' }}>
                        <i
                            style={{
                                position: 'absolute',
                                width: '40px',
                                height: '100%',
                                top: '-5px',
                            }}
                        >
                            <img
                                style={{ marginRight: '8px' }}
                                src="/assets/svg/toast_success.svg"
                                alt=""
                            />
                        </i>
                        <div className={'toast-font'}>{msg}</div>
                    </div>
                ),
                {
                    toastId: customId,
                    autoClose: autoCloseTime,
                },
            );
            return;

        case 'warning':
            toast.warning(
                ({ closeToast }) => (
                    <div style={{ position: 'relative' }}>
                        <i
                            style={{
                                position: 'absolute',
                                width: '40px',
                                height: '100%',
                                top: '-5px',
                            }}
                        >
                            <img
                                style={{ marginRight: '8px' }}
                                src="/assets/svg/toast_warning.svg"
                                alt=""
                            />
                        </i>
                        <div className={'toast-font'}>{msg}</div>
                    </div>
                ),
                {
                    toastId: customId,
                },
            );
            return;

        case 'error':
            toast.error(
                ({ closeToast }) => (
                    <div style={{ position: 'relative' }}>
                        <i
                            style={{
                                position: 'absolute',
                                width: '40px',
                                height: '100%',
                                top: '-5px',
                            }}
                        >
                            <img
                                style={{ marginRight: '8px' }}
                                src="/assets/svg/toast_error.svg"
                                alt=""
                            />
                        </i>
                        <div className={'toast-font'}>{msg}</div>
                    </div>
                ),
                {
                    toastId: customId,
                    autoClose: autoCloseTime,
                },
            );
            return;

        case 'offline':
            toast.error(
                ({ closeToast }) => (
                    <div style={{ position: 'relative' }}>
                        <i
                            style={{
                                position: 'absolute',
                                width: '180px',
                                height: '100%',
                                top: '-5px',
                            }}
                        >
                            <img
                                style={{ marginRight: '8px' }}
                                src="/assets/svg/toast_error.svg"
                                alt=""
                            />
                        </i>
                        <div className={'toast-font'}>{msg}</div>
                    </div>
                ),
                {
                    position: 'top-center',
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    toastId: customId,
                },
            );
            return;

        case 'online':
            toast.dismiss();
            toast.success(
                ({ closeToast }) => (
                    <div style={{ position: 'relative' }}>
                        <i
                            style={{
                                position: 'absolute',
                                width: '180px',
                                height: '100%',
                                top: '-5px',
                            }}
                        >
                            <img
                                style={{ marginRight: '8px' }}
                                src="/assets/svg/toast_success.svg"
                                alt=""
                            />
                        </i>
                        <div className={'toast-font'}>{msg}</div>
                    </div>
                ),
                {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    toastId: customId,
                },
            );
            return;

        case 'socket-offline':
            toast.error(
                ({ closeToast }) => (
                    <div style={{ position: 'relative' }}>
                        <i
                            style={{
                                position: 'absolute',
                                width: '180px',
                                height: '100%',
                                top: '-5px',
                            }}
                        >
                            <img
                                style={{ marginRight: '8px' }}
                                src="/assets/svg/toast_error.svg"
                                alt=""
                            />
                        </i>
                        <div className={'toast-font'}>{msg}</div>
                    </div>
                ),
                {
                    position: 'top-center',
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                    toastId: customId,
                },
            );
            return;
        default:
            return;
    }
}

export const dateLastMonth = (date, lastSomeMonthsNo) => {
    let newDate = date && !/[a-zA-Z]/.test(date) ? dayjs(date, 'YYYY-MM').toDate() : new Date();
    const lastNo = lastSomeMonthsNo ? lastSomeMonthsNo : 1;
    newDate.setMonth(newDate.getMonth() - lastNo);
    return dayjs(newDate).format('YYYY-MM-DD');
};

export const getCurrentFinancialYear = () => {
    const date = new Date();
    const month = date.getMonth();
    if (month > 2) return date.getFullYear();
    return date.getFullYear() - 1;
};

// eslint-disable-next-line no-extend-native
Number.prototype.toFixedNoRounding = function (n) {
    const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + n + '})?', 'g');
    const a = this.toString().match(reg) !== null ? this.toString().match(reg)[0] : this.toString();
    const dot = a.indexOf('.');
    if (dot === -1) {
        // integer, insert decimal dot and pad up zeros
        return a + '.' + '0'.repeat(n);
    }
    const b = n - (a.length - dot) + 1;
    return b > 0 ? a + '0'.repeat(b) : a;
};

export const priceFormatNoRounding = (price, decimal) => {
    return price
        ? Number(Number(price).toFixedNoRounding(decimal)).toLocaleString('en-IN', {
              minimumFractionDigits: decimal,
          })
        : '0';
};

export const priceFormat = (price, decimal) => {
    return price
        ? Number(Number(price).toFixed(decimal)).toLocaleString('en-IN', {
              minimumFractionDigits: decimal,
          })
        : '0';
};

export const priceDecimalRoundUpToSecceedingOnes = (price) => {
    return price ? Math.ceil(Number(price)).toLocaleString('en-IN') : '0';
};

export const reverseOfPriceFormat = (price) => {
    let result = '';
    for (let i of price) {
        if (i !== ',') result += i;
    }
    return result;
};

export const amountUnitFormat = (amount, unit) => {
    if (amount > 10000000) {
        switch (unit) {
            case 'Cr':
                return (amount / 10000000).toFixed(2) + ' Cr';
            case 'L':
                return (amount / 100000).toFixed(2) + ' L';
            default:
                return;
        }
    } else {
        return priceFormat(amount);
    }
};

export const getAmountInLacs = (amount) => {
    return Number(amount / LAKH).toFixed(2);
};
export const convertLacsToCrores = (amount) => {
    return Number(amount / 100).toFixed(2);
};

export const getAmountInCrores = (amount) => {
    return Number(amount / CRORE).toFixed(2);
};

export const fundingCalcAmount = (amount) => {
    if (amount >= 100000) {
        if (amount >= 10000000) {
            return `${(Math.trunc((amount / 10000000) * 100) / 100).toFixed(2)} Cr`;
        } else {
            return `${(Math.trunc((amount / 100000) * 100) / 100).toFixed(2)} L`;
        }
    } else if (amount < 0) return 0;
    else {
        return priceFormat(Math.floor(amount));
    }
};

export const fundingCalcAmountV2 = (amount) => {
    if (amount >= 100000) {
        if (amount >= 10000000) {
            return `${(((amount / 10000000) * 100) / 100).toFixed(2)} Cr`;
        } else {
            return `${(((amount / 100000) * 100) / 100).toFixed(2)} L`;
        }
    } else if (amount < 0) return 0;
    else {
        return priceFormat(Math.floor(amount));
    }
};

export const fundingCalcAmountInLacs = (amount) => {
    if (amount > 0) {
        return `${(((amount / 100000) * 100) / 100).toFixed(2)} L`;
    } else {
        return 0;
    }
};

export const returnValueInLacOrCr = (amount, units, toFixed, roundOffDecimal) => {
    const roundOffAsPerToFixed = !isNil(toFixed) ? 10 ** toFixed : 100;
    if (amount >= CRORE) {
        const showUnits = units ? units.CRORES : 'Cr';
        if (roundOffDecimal === ROUND_OFF.UP)
            return `${
                Math.ceil((amount / CRORE) * roundOffAsPerToFixed) / roundOffAsPerToFixed
            } ${showUnits}`;
        if (roundOffDecimal === ROUND_OFF.DOWN)
            return `${
                Math.floor((amount / CRORE) * roundOffAsPerToFixed) / roundOffAsPerToFixed
            } ${showUnits}`;
        return `${(amount / CRORE).toFixed(toFixed ?? 2)} ${showUnits}`;
    } else {
        const showUnits = units ? units.LAKHS : 'Lacs';
        if (roundOffDecimal === ROUND_OFF.UP)
            return `${
                Math.ceil((amount / LAKH) * roundOffAsPerToFixed) / roundOffAsPerToFixed
            } ${showUnits}`;
        if (roundOffDecimal === ROUND_OFF.DOWN)
            return `${
                Math.floor((amount / LAKH) * roundOffAsPerToFixed) / roundOffAsPerToFixed
            } ${showUnits}`;
        return `${(amount / LAKH).toFixed(toFixed ?? 2)} ${showUnits}`;
    }
};

export function validURL(str) {
    var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // fragment locator
    return !!pattern.test(str);
}

export function payOutTooltip(item, page, repayment_schedule, custom_tenure) {
    let payOut;
    let start_date;
    let last_date;
    let tenure_listed = item.custom_tenure_listed;
    if (page === 'tradebook') {
        if (item.is_contract_traded) {
            payOut = item.investor_payment_amount;
            start_date = dayjs(new Date(item.contract_trade_date));
            last_date = item.last_repayment;
        } else {
            payOut = item.contract_xnpv_price_terms
                ? item.contract_xnpv_price_terms[item.keys_to_pick[tenure_listed - 1]]?.xnpv
                : 0;
            start_date = dayjs();
            last_date =
                !_.isEmpty(repayment_schedule) &&
                repayment_schedule[repayment_schedule.length - 1]?.date;
        }
    } else {
        payOut = item.contract_xnpv_price_terms
            ? item.contract_xnpv_price_terms[item.keys_to_pick[custom_tenure - 1]].xnpv
            : 'NA';
        start_date = dayjs();
        last_date =
            !_.isEmpty(repayment_schedule) &&
            repayment_schedule[repayment_schedule.length - 1]?.date;
    }

    let termValue;
    if (page === 'cart') {
        termValue = item.contract_id.contract_term_value;
    } else if (page === 'trade') {
        termValue = item.contract_xnpv_price_terms
            ? item.contract_xnpv_price_terms[item.keys_to_pick[custom_tenure - 1]].terms
            : 0;
    } else if (page === 'tradebook' && item.is_contract_traded) {
        termValue = item.contract_term_value;
    } else if (page === 'tradebook' && item.is_contract_traded === false) {
        termValue = item.contract_xnpv_price_terms
            ? item.contract_xnpv_price_terms[item.keys_to_pick[tenure_listed - 1]].terms
            : 0;
    }

    let termUnit;
    let recurringPaymentValue;
    let term;
    if (page === 'cart') {
        termUnit = item.contract_id.contract_term_unit?.slice(
            0,
            item.contract_id.contract_term_unit.length - 2,
        );
        recurringPaymentValue = item.contract_id.recurring_payment_value;
        term = item.term;
    } else if (page === 'trade') {
        term = item.contract_xnpv_price_terms
            ? item.contract_xnpv_price_terms[item.keys_to_pick[custom_tenure - 1]].terms +
              ` ${getRepaymentFrequencyAndGapValue(item.contract_term_unit).displayUnit}(s)`
            : 'NA';
        termUnit = item.contract_term_unit?.slice(0, item.contract_term_unit.length - 2);
        recurringPaymentValue = item.recurring_payment_value;
    } else if (page === 'tradebook' && item.is_contract_traded) {
        term =
            item.contract_term_value +
            ` ${getRepaymentFrequencyAndGapValue(item.contract_term_unit).displayUnit}(s)`;
        termUnit = item.contract_term_unit?.slice(0, item.contract_term_unit.length - 2);
        recurringPaymentValue = item.recurring_payment_value;
    } else if (page === 'tradebook' && item.is_contract_traded === false) {
        let tenure_listed = item.custom_tenure_listed;
        term = item.contract_xnpv_price_terms
            ? item.contract_xnpv_price_terms[item.keys_to_pick[tenure_listed - 1]]?.terms +
              ` ${getRepaymentFrequencyAndGapValue(item.contract_term_unit).displayUnit}(s)`
            : 'NA';
        termUnit = item.contract_term_unit?.slice(0, item.contract_term_unit.length - 2);
        recurringPaymentValue = item.recurring_payment_value;
    }

    const perUnitPrice = (payOut / termValue).toFixed(0);
    const price = payOut / (recurringPaymentValue * termValue);
    // const formattedPrice = price.toFixed(4);
    const discount = ((1 - price) * 100).toFixed(2);
    const days = Math.ceil(dayjs(new Date(last_date)).diff(start_date, 'd', true));
    return (
        <span style={{ width: '170px', lineHeight: '19px', fontWeight: 300 }}>
            {'Equivalent to'} <i className="fa fa-rupee-sign"></i>
            {`${perUnitPrice?.toLocaleString('en-IN')}/${termUnit} prepaid to you for
            next ${term} at a discount of ${discount}% for ${days} day(s) financing`}
        </span>
    );
}

export const scrollToTop = (ref) => {
    ref.current?.scrollIntoView();
    window.scrollTo(0, 0);
};

export const filterChecker = (arr) => {
    for (let item of arr) {
        if (item.status) {
            return true;
        }
    }
    return false;
};

export const basePathInvestee = (sidebarConfig, widget) => {
    const { ONBOARDING, ADD_ON_LIMIT, LIMIT_DOCUMENTS, DASHBOARD, RECUR_PRODUCTS } = SIDEBAR_LABELS;
    const notAllowedRoutes = getNotAllowedRoutes(sidebarConfig, widget);
    if (!sidebarConfig || !widget)
        return ROUTES_MAP?.[DASHBOARD] ?? BASE_PATH_INVESTEE_APPROVED_ACTIVE;
    if (!notAllowedRoutes.includes(ROUTES_MAP[ONBOARDING])) return ROUTES_MAP[ONBOARDING];
    if (!notAllowedRoutes.includes(ROUTES_MAP[ADD_ON_LIMIT])) return ROUTES_MAP[ADD_ON_LIMIT];
    //@TODO change this from backend ideally offer var should come.
    if (!notAllowedRoutes.includes(ROUTES_MAP[LIMIT_DOCUMENTS])) return ROUTES_MAP[RECUR_PRODUCTS];
    return ROUTES_MAP[DASHBOARD];
};

export const getDefaultBasePath = (isAuthenticated) => {
    let userType = storage.get(USER_TYPE_INVESTEE) || storage.get(USER_TYPE_INVESTEE);
    if (!isAuthenticated && userType === USER_SEGMENT_TYPE.investee) return INVESTEE_BASE_PATH;
    if (!isAuthenticated && userType === USER_SEGMENT_TYPE.investor) return INVESTOR_BASE_PATH;
};

export const getNotAllowedRoutes = (sidebarConfig, widget) => {
    const { ONBOARDING, ADD_ON_LIMIT, LIMIT_DOCUMENTS, DASHBOARD, LIMIT_OVERVIEW } = SIDEBAR_LABELS;
    const notAllowedRoutes = [];
    if (!isEmpty(sidebarConfig)) {
        for (let item of sidebarConfig) {
            if (item.label !== 'Header') {
                if (!item.display) {
                    if (item.label === DASHBOARD) notAllowedRoutes.push(ROUTES_MAP[LIMIT_OVERVIEW]);
                    notAllowedRoutes.push(ROUTES_MAP[item.label]);
                }
            }
        }
    }
    if (!isEmpty(widget)) {
        if (widget.label === WIDGETS.APPLICATION_WIDGET) {
            notAllowedRoutes.push(ROUTES_MAP[ADD_ON_LIMIT]);
            if (widget.applicationStatus === 'Approved')
                notAllowedRoutes.push(ROUTES_MAP[ONBOARDING]);
        } else notAllowedRoutes.push(ROUTES_MAP[ONBOARDING]);
        if (blockWidgetStep(widget, CATEGORIES.DATA)) {
            if (widget.applicationStatus === 'Submitted') return notAllowedRoutes;
            if (widget.label === WIDGETS.APPLICATION_WIDGET) {
                if (widget.applicationStatus !== ONBOARDING_STATUS.EXPIRED)
                    notAllowedRoutes.push(ROUTES_MAP[ONBOARDING]);
                if (widget.applicationStatus === 'Approved')
                    notAllowedRoutes.push(ROUTES_MAP[ADD_ON_LIMIT]);
            } else notAllowedRoutes.push(ROUTES_MAP[ADD_ON_LIMIT]);
        }
        if (blockWidgetStep(widget, CATEGORIES.KYC))
            notAllowedRoutes.push(ROUTES_MAP[LIMIT_DOCUMENTS]);
    }
    return [...new Set(notAllowedRoutes)];
};

export function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
}

/**
 *
 * @param {string in uppercase} // AMAN GUPTA ---> Aman Gupta
 */
export const convertStrIntoCamelCase = (str) => {
    return str
        ? str
              .split(' ')
              .map((s) => s[0]?.toUpperCase() + s.slice(1).toLowerCase())
              .join(' ') //.replace(/^./, function(str){ return str.toUpperCase(); })
        : '';
};

export const detectMobile = () => {
    return !!window && window.innerWidth <= 1025;
};

export function downloadFileUsingFetch(
    url,
    method = 'get',
    input,
    argHeaders,
    fileName,
    cb,
    isWebutils,
    isOlypmus,
) {
    const token = storage.get('cerberus_token') ?? '';
    const userId = storage.get('user_id') ?? '';
    const orgId = storage.get('org_id') ?? '';
    let endpoint = '';
    if (isWebutils) {
        endpoint = `${WEBUTILS_URL}/utilities/rest/${url}`;
    } else if (isOlypmus) {
        endpoint = `${OLYMPUS_URL}${url}`;
    } else {
        endpoint = `${API_URL}/api/${url}`;
    }
    const headers = { ...argHeaders };
    if (token && token !== '') {
        headers['x-cerberus-token'] = token;
        if (userId && userId !== '') {
            headers['x-user-id'] = userId;
        }
        if (orgId && orgId !== '') {
            headers['x-organization-id'] = orgId;
        }
    }
    const obj = {};
    obj.method = method;
    if (method === 'post') {
        obj.body = JSON.stringify(input);
    }
    obj.headers = headers;
    fetch(endpoint, obj)
        .then((res) => {
            if (res.status >= 200 && res.status <= 300) return res.blob();
            else throw new Error('Some error occurred!');
        })
        .then((blob) => {
            const newBlob = new Blob([blob]);
            const url = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            cb && cb();
        })
        .catch(({ message }) => {
            showNotification('Error', message);
            cb && cb();
        });
}

export async function downloadXLSXFile(url, input, cb) {
    try {
        const res = await getTdsFilingReportDownload(url, input);
        if (res?.length > 0) {
            const data = convertData(res);
            convertJsontoExcel(data, 'TDS_Filing_Report');
            cb && cb();
        } else throw new Error('No data for the month');
    } catch (err) {
        const message = err?.data?.message || err?.message || 'Some error occured';
        showNotification('Error', message);
        cb && cb();
    }
}

//create xlsx file and download
const convertJsontoExcel = (data, FileName) => {
    let Heading = [
        [
            'Fees/Interest Date',
            'Due Date of Deposit',
            'Deductee Name',
            'PAN',
            'Section',
            'Fees/Interest',
            'Rate',
            'TDS Amount',
        ],
    ];
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(workSheet, Heading);
    XLSX.utils.sheet_add_json(workSheet, data, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(workBook, workSheet, FileName);
    XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
    XLSX.writeFile(workBook, `${FileName}.xlsx`);
};

//convertData in proper format for excel sheet
const convertData = (data) => {
    let updateData = [];
    for (let element of data) {
        let obj = {};
        if (element.fee_interest_date && element.due_date) {
            obj.fee_interest_date = dayjs(element.fee_interest_date).format('DD MMM YYYY');
            obj.due_date = dayjs(element.due_date).format('DD MMM YYYY');
        } else continue;
        obj.deductee_name = element.deductee_name || '';
        obj.pan = element.pan || '';
        obj.section = element.section || '';
        obj.fee_interest_amt = +element.fee_interest_amt.toLocaleString('en-IN') || '';
        obj.rate = element.rate + '%' || '';
        obj.tds_amount = element.tds_amount || '';
        updateData.push(obj);
    }
    return updateData;
};

export const checkIfIntegrated = (integrationObj) => {
    let hasIntegrated = '';
    for (let i in integrationObj) {
        if (integrationObj[i] && sources.includes(i)) {
            hasIntegrated = i;
            break;
        }
    }
    return hasIntegrated;
};

export const handleIntegration = (subStage, logoName, integrationObj, isOnboarding, cb) => {
    let Redirect_URI = isOnboarding
        ? process.env.REACT_APP_ZOHO_REDIRECT_URI_ONBOARDING
        : process.env.REACT_APP_ZOHO_REDIRECT_URI_DATA_VAULT;
    const hasIntegrated = checkIfIntegrated(integrationObj);
    if (hasIntegrated) return;
    else {
        if (logoName === 'tally') {
            cb();
        } else {
            Redirect_URI +=
                subStage === 1
                    ? `?misIntegrations=${logoName}`
                    : `?invoicingIntegrations=${logoName}`;
            const link = document.createElement('a');
            if (logoName === 'zoho') {
                link.href = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoInvoice.invoices.READ,ZohoBooks.invoices.READ,ZohoBooks.creditnotes.READ,ZohoBooks.customerpayments.READ,ZohoBooks.bills.READ,ZohoBooks.vendorpayments.READ,ZohoBooks.banking.READ,ZohoBooks.accountants.READ,ZohoBooks.settings.READ,ZohoSubscriptions.customers.READ,ZohoSubscriptions.subscriptions.READ,ZohoSubscriptions.invoices.READ,ZohoSubscriptions.creditnotes.READ,ZohoSubscriptions.products.READ,ZohoSubscriptions.plans.READ,ZohoSubscriptions.addons.READ,ZohoSubscriptions.coupons.READ,ZohoSubscriptions.payments.READ,ZohoSubscriptions.settings.READ,ZohoBooks.reports.READ&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&response_type=code&redirect_uri=${Redirect_URI}&access_type=offline&&prompt=Consent`;
            }
            document.body.appendChild(link);
            link.click();
        }
    }
};

export const removeItemFromArray = (arr, val) => {
    const index = arr.indexOf(val);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
};

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

export function encryptMessage(message) {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(process.env.REACT_APP_ENC_PUB_KEY);
    return jsEncrypt.encrypt(message);
}

export const downloadUsingArrayBuffer = (file_url, setDownloadStarted, type, onSuccess) => {
    if (file_url) {
        setDownloadStarted(true);
        return callApi(`download-holding-certificate`, 'post', { fileUrl: file_url }).then(
            (res) => {
                if (res.status === API_STATUS.SUCCESS && res.data) {
                    var array = new Uint8Array(res.data.Body.data);
                    var blob = new Blob([array], type);
                    var url = URL.createObjectURL(blob);
                    // const base64String = btoa(String.fromCharCode(...new Uint8Array(res.data.Body.data)));
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.href = url;
                    // a.href = "data:application/pdf;base64," + base64String;
                    a.download = file_url.split('/').slice(-1)[0];
                    a.click();
                    setDownloadStarted(false);
                    if (onSuccess) onSuccess();
                    showNotification('Success', 'Download Successful');
                } else {
                    setDownloadStarted(false);
                    showNotification('Error', 'Something Went Wrong');
                }
            },
        );
    } else {
        showNotification('Error', 'Document not available');
    }
};

export function getObjAndFileNameS3(url, bucket) {
    if (Number.isInteger(Number(url))) {
        const obj = { url, bucket };
        return { obj, fileName: 'file', extention: null };
    }
    const commonStringInUrl = 'amazonaws.com/';
    const indexOfCommonString = url.indexOf(commonStringInUrl);
    const urlToSend = url.slice(indexOfCommonString + commonStringInUrl.length);
    const obj = { url: urlToSend, bucket: bucket };
    const fileName = urlToSend.substr(urlToSend.indexOf('/') + 1);

    const extentionArr = obj?.url?.split('.');
    const extention = extentionArr[extentionArr?.length - 1];
    return { obj, fileName, extention };
}

export function downloadFileFromArrayBuffer(
    url,
    method,
    body,
    headers,
    fileName,
    bufferType,
    getBuffer,
    cb,
    errorCb,
) {
    callApi(url, method, body, headers)
        .then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                const array = new Uint8Array(getBuffer(res.data));
                const blob = new Blob([array], bufferType);
                const win_url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = win_url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                cb && cb();
            } else {
                if (errorCb) {
                    errorCb(res);
                } else {
                    showNotification('Error', 'Some error occurred');
                    cb && cb();
                }
            }
        })
        .catch((err) => {
            showNotification('Error', 'Some error occurred');
            cb && cb();
        });
}

export const getCorrectlistedPerc = (contractObj) => {
    if (contractObj.listed_perc === contractObj?.contract_xnpv_price_terms[0]?.listed_perc) {
        return 1;
    } else {
        //if xnpv array and actual listed perc mismatches means xnpv array do not have correct value according to new listed percentage
        return contractObj.listed_perc / 100;
    }
};

export const nameSplitter = (contactName) => {
    const name = contactName ? contactName : '';
    let arr = name ? name.split(' ') : [];
    let firstName;
    let lastName;
    if (arr.length === 1) {
        firstName = arr.pop();
        lastName = '';
    } else {
        lastName = arr.pop();
        firstName = arr.join(' ');
    }
    return {
        firstName,
        lastName,
    };
};

export const hideSensitiveText = (text) => {
    return `****${text.substr(text.length - 4)}`;
};

export const onBackButtonClick = (onClick) => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function (event) {
        onClick && onClick();
    });
};

export const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
    },
});

export function timeDiff(startDate, endDate) {
    // Converting upcoming startDate into IST since endDate is alrdy in IST
    // startDate.setHours(startDate.getHours() + 5);
    // startDate.setMinutes(startDate.getMinutes() + 30);
    let diff = endDate.getTime() - startDate.getTime();
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);
    diff -= minutes * 1000 * 60;
    const seconds = Math.floor(diff / 1000);
    return (
        (hours <= 9 ? '0' : '') +
        hours +
        ':' +
        (minutes <= 9 ? '0' : '') +
        minutes +
        ':' +
        (seconds <= 9 ? '0' : '') +
        seconds
    );
}

export const calcTimeDiff = (submissionDate) => {
    const endDate = new Date(submissionDate);
    endDate.setHours(endDate.getHours() + 24);
    // Converting endDate into IST since startDate is in IST
    endDate.setHours(endDate.getHours() + 5);
    endDate.setMinutes(endDate.getMinutes() + 30);
    const startDate = new Date();
    let diff = endDate.getTime() - startDate.getTime();
    if (diff <= 0) return '00:00:00';
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);
    diff -= minutes * 1000 * 60;
    const seconds = Math.floor(diff / 1000);
    return (
        (hours <= 9 ? '0' : '') +
        hours +
        ':' +
        (minutes <= 9 ? '0' : '') +
        minutes +
        ':' +
        (seconds <= 9 ? '0' : '') +
        seconds
    );
};

export const getTimeLeft = (timeLeftInMS) => {
    if (timeLeftInMS <= 0) return TIME_ENDS;
    if (timeLeftInMS) {
        let diff = timeLeftInMS;
        if (diff <= 0) return TIME_ENDS;
        const hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(diff / 1000 / 60);
        diff -= minutes * 1000 * 60;
        const seconds = Math.floor(diff / 1000);
        return (
            (hours <= 9 ? '0' : '') +
            hours +
            ':' +
            (minutes <= 9 ? '0' : '') +
            minutes +
            ':' +
            (seconds <= 9 ? '0' : '') +
            seconds
        );
    }
};

export const reduceOneSecond = (date) => {
    if (date) {
        const time = date.split(':');
        let hrs = Number(time[0]);
        let mins = Number(time[1]);
        let secs = Number(time[2]);
        if (secs === 0) {
            if (mins === 0) {
                if (hrs !== 0) {
                    mins = 59;
                    secs = 59;
                    hrs--;
                }
            } else {
                mins--;
                secs = 59;
            }
        } else secs--;
        return (
            (hrs <= 9 ? '0' : '') +
            hrs +
            ':' +
            (mins <= 9 ? '0' : '') +
            mins +
            ':' +
            (secs <= 9 ? '0' : '') +
            secs
        );
    }
};

export const getPaymentsDoneAndRemaining = (
    is_contract_traded,
    recurring_payment_value,
    payouts_done,
    traded_terms,
    contract_xnpv_price_terms,
    keys_to_pick,
    custom_tenure_listed,
    listed_perc,
) => {
    const obj = {};
    if (is_contract_traded) {
        obj.done = recurring_payment_value * payouts_done;
        obj.total = recurring_payment_value * traded_terms;
    } else {
        obj.done = 0;
        obj.total =
            contract_xnpv_price_terms[keys_to_pick[custom_tenure_listed - 1]].terms *
            recurring_payment_value *
            ((listed_perc ?? 100) / 100);
    }
    obj.remaining = obj.total - obj.done;
    return obj;
};

export const setupHubspotLiveChat = (userDetails, hubspotToken, fetchHubspotToken) => {
    if (process.env.REACT_APP_ENVIRONMENT !== PRODUCTION) return;
    const { firstName, lastName } = nameSplitter(userDetails.contact_name);
    if (!hubspotToken) {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.id = 'hs-script-loader';
        scriptTag.async = true;
        scriptTag.defer = true;
        scriptTag.src = '//js-eu1.hs-scripts.com/24889911.js';
        document.body.appendChild(scriptTag);
        window.hsConversationsSettings = {
            loadImmediately: false,
        };
        fetchHubspotToken(firstName, lastName);
    } else {
        window.hsConversationsSettings = {
            loadImmediately: true,
            identificationEmail: userDetails.contact_email,
            identificationToken: hubspotToken,
        };
        if (window.HubSpotConversations) {
            window.HubSpotConversations.widget.load();
        } else {
            window.hsConversationsOnReady = [
                () => {
                    window.HubSpotConversations.widget.load();
                },
            ];
        }
    }
};

export const setupHubspotLiveChatV2 = (userDetails, hubspotToken, fetchHubspotToken) => {
    if (process.env.REACT_APP_ENVIRONMENT !== PRODUCTION) return;
    const { firstName, lastName } = nameSplitter(userDetails.contact_name);
    const loadHubspot = () => {
        const hubspotHolder = document.getElementById('hubspot-chat-holder');
        window.addEventListener('productfruits_button_ext_widget_init', () => {
            window.productFruits.api.button.hideButton();
            window.HubSpotConversations.widget.load();
            hubspotHolder.style.visibility = 'visible'; // if we get signal from PF Life Ring button, show the HubSpot element
            hubspotHolder.style.zIndex = 1000;
        });
    };
    if (!hubspotToken) {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.id = 'hs-script-loader';
        scriptTag.async = true;
        scriptTag.defer = true;
        scriptTag.src = '//js-eu1.hs-scripts.com/24889911.js';
        document.body.appendChild(scriptTag);
        window.hsConversationsSettings = {
            loadImmediately: false,
        };
        fetchHubspotToken(firstName, lastName);
    } else {
        window.hsConversationsSettings = {
            loadImmediately: true,
            inlineEmbedSelector: '#hubspot-chat',
            identificationEmail: userDetails.contact_email,
            identificationToken: hubspotToken,
        };
        if (window.HubSpotConversations) loadHubspot();
        else window.hsConversationsOnReady = [loadHubspot];
    }
};

export const mixPanelTrackForInvesteeSignupSuccess = (
    userName,
    email,
    phone,
    org,
    workSpaceRoute,
    investee_org_id,
    refCode,
) => {
    Mixpanel.identify(investee_org_id);
    Mixpanel.people.set({
        name: userName,
        $email: email,
        phone: phone,
        company_name: org,
        signup_medium: workSpaceRoute ? ROUTE.GOOGLE : ROUTE.NORMAL,
        investee_organization_id: investee_org_id,
        registration_date: dayjs().utc().utcOffset(330).format(DAYJS_DATE_FORMAT),
        utm_signup: window.localStorage.getItem('incomingQuerySignup'),
        is_terms_of_use_clicked: window.localStorage.getItem('is_terms_of_use_clicked')
            ? window.localStorage.getItem('is_terms_of_use_clicked')
            : 'no',
        is_privacy_policy_clicked: window.localStorage.getItem('is_privacy_policy_clicked')
            ? window.localStorage.getItem('is_privacy_policy_clicked')
            : 'no',
        referral_code: refCode,
        ip_address: window.localStorage.getItem('myIp'),
    });
    // event properties should flow to user property
    Mixpanel.track('signup', {
        $email: email,
        $distinct_id: investee_org_id,
        investee_organization_id: investee_org_id,
        registration_date: dayjs().utc().utcOffset(330).format(DAYJS_DATE_FORMAT),
        ip_address: window.localStorage.getItem('myIp'),
    });
};

export const closeHubspotLiveChat = () => {
    window.HubSpotConversations?.widget?.remove(); // To close live chat if is already on!
    const hubspotHolder = document.getElementById('hubspot-chat-holder');
    if (hubspotHolder) {
        hubspotHolder.style.visibility = 'hidden';
        hubspotHolder.style.zIndex = 0;
    }
    if (window.productFruits && window.productFruits.api && window.productFruits.api.button)
        window.productFruits.api.button.showButton();
};

// Gives us tag from roleId
export const getRoleName = (roles, roleObjectMap) => {
    if (
        roles &&
        typeof roles === 'object' &&
        roles[0] &&
        roleObjectMap &&
        roleObjectMap[roles[0]]
    ) {
        return roleObjectMap[roles[0]].tag;
    }
    return 'NA';
};

// Gives us rolename from roleId
export const getExactRoleName = (roles, roleObjectMap) => {
    if (
        roles &&
        typeof roles === 'object' &&
        roles[0] &&
        roleObjectMap &&
        roleObjectMap[roles[0]]
    ) {
        return roleObjectMap[roles[0]].name;
    }
    return 'NA';
};

export const getPrimaryGstAndState = (gstin) => {
    if (!gstin) return null;
    for (let gst in gstin)
        if (gstin[gst].is_primary) {
            return {
                gst: gstin[gst].gstin_number,
                state: gstin[gst].state,
                gstDetail: gstin[gst],
            };
        }
    return null;
};

export const changePrimaryGst = (investee_onboarded_id, oldGst, newGst, onSuccess) => {
    if (oldGst !== newGst) {
        callApi('investee_kyc_application/change-primary-gst', 'post', {
            investee_onboarded_id,
            curr_primary_gstin_number: oldGst,
            new_primary_gstin_number: newGst,
        }).then((res) => {
            if (res.status === API_STATUS.SUCCESS) {
                if (onSuccess) onSuccess(res.data);
            } else {
                const message = res.data?.message ?? 'Some error occurred!';
                showNotification('Error', message);
            }
        });
    }
};

export const dealChestUserRedirect = () => {
    window.open(DEAL_CHEST_WEBSITE, '_blank');
};

export function camelize(str) {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
}

export function updateParentObj({ parentObj, childObj }) {
    // parentObj: main obj with extra entities; childObj: obj with limited entities but with updated values
    // motive is to return the parentObj with updated values which we have in childObj
    const obj = { ...parentObj };
    for (let i in childObj) obj[i] = childObj[i];
    return obj;
}

export function splitCaseInsensitive({ mainStr, subStr }) {
    if (typeof mainStr !== 'string' || typeof subStr !== 'string') return;
    const regEscape = (v) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    return mainStr.split(new RegExp(regEscape(subStr), 'ig'));
}

export const isThereAuthComponents = () => {
    let userId = storage.get('user_id');
    let orgId = storage.get('org_id');
    let token = storage.get('cerberus_token');
    return token && userId && orgId;
};
export const signDocument = async (deal, investorOrgId, userName) => {
    if (investorOrgId && userName) {
        mixPanelTrack({
            id: investorOrgId,
            trackUserObj: {
                clicked_by: userName,
                company_name: deal?.investeeorganization
                    ? deal?.investeeorganization?.display_name
                    : deal?.displayName,
            },
            trackCategoryName: 'Counter sign MFA',
        });
    }
    const reloadWindow = () => {
        window.location.reload();
    };
    setTimeout(() => {
        window.addEventListener('mousemove', reloadWindow);
    }, 30000); //user goes for signing on new tab , we attach mouse move event till then so that page rereshes when user comes back
    const data = {
        dealId: deal?.investordeal
            ? deal?.investordeal?._id
                ? deal?.investordeal?._id
                : deal?._id
            : deal.investorDealId,
    };
    const res = await callRegenerateCounterSigningLink(data);
    if (res) {
        window.open(res?.counterSigningLink, '_blank');
    } else {
        showNotification('Error', 'Error In fetching Counter Signing Link');
        window.removeEventListener('mousemove', reloadWindow);
    }
};
export const numberOnly = (value) => {
    return value.replace(/[^0-9]/g, '');
};
export const decimalAndNumberOnly = (value) => {
    return value.replace(/[^0-9.]/g, '');
};

const checkBlackListedUser = (objName) => {
    const blackListedName = ['zeus investor', 'zeus company'];
    const blackListedEmail = ['zeus.investor@recur.club', 'zeus.company@recur.club'];

    if (!isEmpty(objName)) {
        for (let prop in objName) return blackListedName.includes(objName[prop]); // check for name
        for (let prop in objName) return blackListedEmail.includes(objName[prop]); // check for email
    }
};

export const mixPanelTrack = ({ id, setUserObj = {}, trackUserObj, trackCategoryName }) => {
    if (!isEnvProd) return;
    const isBlackListed = checkBlackListedUser(setUserObj) || checkBlackListedUser(trackUserObj);
    if (isBlackListed) return;
    Mixpanel.identify(id);
    if (setUserObj)
        Mixpanel.people.set({
            ...setUserObj,
            ip_address: window.localStorage.getItem('myIp'),
            $time: dayjs().utc().utcOffset(330).format(DAYJS_DATE_FORMAT),
            userAgent: window.navigator.userAgent,
        });
    // event properties should flow to user property
    if (trackUserObj)
        Mixpanel.track(trackCategoryName, {
            ...trackUserObj,
            ip_address: window.localStorage.getItem('myIp'),
            $time: dayjs().utc().utcOffset(330).format(DAYJS_DATE_FORMAT),
            userAgent: window.navigator.userAgent,
        });
};

export const mixPanelTrackForInvestmentReportDownload = ({
    id,
    userName,
    is_investment_report_downloaded_from_modal,
}) => {
    mixPanelTrack({
        id,
        trackUserObj: {
            downloaded_by: userName,
            is_investment_report_downloaded_from_modal: is_investment_report_downloaded_from_modal
                ? 'yes'
                : 'no',
        },
        trackCategoryName: 'Investment report download',
    });
};

export const mixPanelTrackForExportCsv = ({ id, dataExportedFrom, userName }) => {
    mixPanelTrack({
        id,
        trackCategoryName: 'Investor Export csv',
        trackUserObj: {
            data_exported_from: dataExportedFrom,
            exported_by: userName,
        },
    });
};

export const exportToCsvClientSide = (tableName, tableRows) => {
    let csvContent = 'data:text/csv;charset=utf-8,' + tableRows.map((e) => e.join(',')).join('\n');
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${tableName}.csv`);
    document.body.appendChild(link);

    link.click();
};
// export const dealApprovalCta = (orgId, ctaName) => {
//     switch (orgId) {
//         case process.env.REACT_APP_INVESTOR_INCRED_ORG_ID:
//             return 'Approve for Disbursement';
//         default:
//             return ctaName;
//     }
// };
export const getNextRepaymentMonths = (morat, maxTenure) => {
    let now = dayjs().utcOffset(330);
    const addMonths = (numberOfMonths) => {
        now = now.add(numberOfMonths, 'month');
    };
    const moratoriumStatus = getMoratoriumStatus(morat);
    if (moratoriumStatus.moratoriumBool) {
        addMonths(moratoriumStatus.moratoriumMonths);
    }
    if (now.get('date') >= 28) addMonths(1);
    now = now.set('date', 28);

    let postDate = [];
    for (let i = 0; i < maxTenure; ++i) {
        postDate.push(now.format('DD/MM/YYYY'));
        addMonths(1);
    }
    return postDate;
};

export const getMoratoriumStatus = (moratorium_months) => {
    const res = {
        moratoriumBool: true,
        moratoriumMonths: DEFAULT_MORAT_VAL_MONTHS,
    };
    if (
        !isNil(moratorium_months) &&
        Number.isInteger(Number(moratorium_months)) &&
        Number(moratorium_months) <= 3 &&
        Number(moratorium_months) >= 1
    ) {
        res.moratoriumBool = true;
        res.moratoriumMonths = Number(moratorium_months);
    }
    if (
        !isNil(moratorium_months) &&
        Number.isInteger(Number(moratorium_months)) &&
        Number(moratorium_months) === 0
    ) {
        res.moratoriumBool = false;
        res.moratoriumMonths = 0;
    }
    return res;
};

export const getMoratoriumEnum = (moratorium_months) => {
    // will take care of null / undefined
    if (
        !isNil(moratorium_months) &&
        Number.isInteger(Number(moratorium_months)) &&
        Number(moratorium_months) <= 3 &&
        Number(moratorium_months) >= 1
    ) {
        return Number(moratorium_months);
    }
    if (
        !isNil(moratorium_months) &&
        Number.isInteger(Number(moratorium_months)) &&
        Number(moratorium_months) === 0
    ) {
        return 0;
    }
    return DEFAULT_MORAT_VAL_MONTHS;
};

export const generateRequestId = () => {
    return `${moment().valueOf()}`;
};

export const checkIfAllBankDetailsFilled = (obj) => {
    for (let i in obj) {
        if (obj[i]?.trim() === '') return FILLING;
    }
    return COMPLETED;
};

export const isEnvProd = process.env.REACT_APP_ENVIRONMENT === 'production';

export function getPrevious12Months() {
    let today = new Date();
    today.setMonth(today.getMonth() - 23);
    let dates = [];
    for (let i = 0; i < 24; i++) {
        let month = today.getMonth();
        let year = today.getFullYear();
        dates.push(`${month + 1 < 10 ? '0' : ''}${month + 1}-${year}`);
        if (month === 0) {
            today.setMonth(1);
        }
        today.setMonth(month + 1);
    }
    return dates;
}

export function getMonthsByYear(numOfMonths) {
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    let endDate = dayjs();
    let endDateMonth = endDate.month() + 1;
    let endDateYear = endDate.year();
    let startDate = dayjs().subtract(numOfMonths, 'months');
    let startDateYear = startDate.year();

    let datesObj = {};
    for (let i = startDateYear; i <= endDateYear; i++) {
        let monthsArr;
        if (i === endDateYear)
            monthsArr = months.filter((month) => parseInt(month) <= endDateMonth);
        else monthsArr = months;
        datesObj[i] = monthsArr;
    }
    return { months, datesObj };
}

export function spinningLoader(props) {
    const { width, height, marginLeft } = props ?? {
        width: null,
        height: null,
        marginLeft: null,
    };
    return (
        <div
            id="loading-spinner"
            style={{ marginLeft: marginLeft ?? '5px', display: 'inline-block' }}
        >
            <div
                className="spin-icon"
                style={{ width: width ?? '10px', height: height ?? '10px' }}
            />
        </div>
    );
}
export const getQuarterStartAndEnd = (date) => {
    // get start and end date of quarter using moment
    const quarterStart = moment(date).startOf('quarter').format('DD-MM-YYYY');
    const quarterEnd = moment(date).endOf('quarter').format('DD-MM-YYYY');
    return { quarterStart, quarterEnd };
};

export function customTimeout(ms, callback) {
    return new Promise((resolve) =>
        setTimeout(() => {
            callback && callback();
            resolve();
        }, ms),
    );
}

export const checkIfArrayIncludesSubstringOfVar = (arr, str) => {
    for (let i of arr) {
        if (str.includes(i)) return true;
    }
    return false;
};
export function joinArraySentenceCase(arr) {
    if (!Array.isArray(arr) || arr.length === 0) return '';
    if (arr.length === 1) return arr[0];
    if (arr.length === 2) return arr.join(' and ');
    return arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
}

export function getTimePassed(date) {
    const now = moment();
    const createdDate = moment(date);
    let timePassed;
    timePassed = now.diff(createdDate, 'seconds');
    if (timePassed === 0) return 'now';
    if (timePassed === 1) return '1 sec';
    if (timePassed < 60) return timePassed + ' secs';
    timePassed = now.diff(createdDate, 'minutes');
    if (timePassed === 1) return '1 min';
    if (timePassed < 60) return timePassed + ' mins';
    timePassed = now.diff(createdDate, 'hours');
    if (timePassed === 1) return '1 hr';
    if (timePassed < 24) return timePassed + ' hrs';
    timePassed = now.diff(createdDate, 'days');
    if (timePassed === 1) return '1 day';
    if (timePassed < 30) return timePassed + ' days';
    timePassed = now.diff(createdDate, 'weeks');
    if (timePassed === 1) return '1 week';
    if (timePassed < 4) return timePassed + ' weeks';
    timePassed = now.diff(createdDate, 'months');
    if (timePassed === 1) return '1 month';
    if (timePassed < 12) return timePassed + ' months';
    timePassed = now.diff(createdDate, 'years');
    if (timePassed === 1) return '1 year';
    return timePassed + ' years';
}

export function convertArrayToEnum(arr = [], property) {
    let arrayToUse = arr ?? [];
    if (!property) return {};
    let obj = {};
    arrayToUse.forEach((item) => {
        let tempBankObj = {};
        for (let key in item) {
            if (key !== 'od' && key !== 'fd') {
                tempBankObj[key] = item[key];
            } else {
                for (let keyInOdfd in item[key]) {
                    tempBankObj[keyInOdfd] = item?.[key]?.[keyInOdfd];
                }
            }
        }
        obj[item[property]] = tempBankObj;
    });
    return obj;
}

// use this function when putting an image in HTML part and for passing iconName, use ICON_NAMES constant created in assetLinks.ts file
export function getS3IconLink(iconName, type = 'svg', flMigrated = false) {
    let orgName = 'fl';
    return `https://${orgName}-fe-assets.s3.ap-south-1.amazonaws.com/${type}/${iconName}.${type}`;
}

export function getS3FLIconLink(iconName, type = 'svg') {
    return `https://fl-fe-assets.s3.ap-south-1.amazonaws.com/${type}/${iconName}.${type}`;
}

export const getTimeLeftDetails = ({
    expiryDate,
    timeLeftInMS,
    showFullDateAfterNDays,
    ExpiredOn,
    ExpiryMin,
    ExpiresOn,
    ExpiryHr,
}) => {
    const countDownTime = getTimeLeft(timeLeftInMS);
    const getDaysNHours = (date) => {
        if (date) {
            const totalHours = Number(date.split(':')[0]);
            const days = Math.floor(totalHours / 24);
            const hours = totalHours - days * 24;
            return {
                days,
                hours,
                totalHours,
            };
        }
    };

    const getHoursNMinutes = (date) => {
        if (date) {
            const time = date.split(':');
            return {
                hours: Number(time[0]),
                mins: Number(time[1]),
            };
        }
    };

    const getTimeInFormat = (date, inDays) => {
        if (date) {
            if (inDays) {
                const { days, hours } = getDaysNHours(date);
                if (hours > 1) return `${days} Days ${hours} hrs`;
                return `${days} Days ${hours} hr`;
            } else {
                const { hours, mins } = getHoursNMinutes(date);
                if (hours > 1) {
                    if (mins > 1) return `${hours} hrs ${mins} mins`;
                    return `${hours} hrs ${mins} min`;
                }
                if (mins > 1) return `${hours} hr ${mins} mins`;
                return `${hours} hr ${mins} min`;
            }
        }
    };
    const obj = {};

    const { days, totalHours } = getDaysNHours(countDownTime) ?? {
        days: null,
        totalHours: null,
    };
    if (!isNil(totalHours) && totalHours < 48) {
        if (countDownTime === TIME_ENDS) {
            obj.mainClass = ExpiredOn ?? '';
            obj.showText = 'Expired on';
            obj.showTimeLeft =
                expiryDate?.length === 10
                    ? dayjs(expiryDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                    : dayjs(expiryDate, 'YYYY-MM-DDTHH:mm:ss').format('DD MMM YYYY');
        } else {
            obj.mainClass = ExpiryMin ?? '';
            obj.showText = 'Expires in';
            obj.showTimeLeft = getTimeInFormat(countDownTime);
        }
    } else {
        if (showFullDateAfterNDays && days && days > showFullDateAfterNDays) {
            obj.mainClass = ExpiresOn ?? '';
            obj.showText = 'Expires on';
            obj.showTimeLeft =
                expiryDate?.length === 10
                    ? dayjs(expiryDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                    : dayjs(expiryDate, 'YYYY-MM-DDTHH:mm:ss').format('DD MMM YYYY');
        } else {
            obj.mainClass = ExpiryHr ?? '';
            obj.showText = 'Expires in';
            obj.showTimeLeft = getTimeInFormat(countDownTime, true);
        }
    }
    return obj;
};

export const downloadAccountingStatement = (
    e,
    isDownloading,
    investeeOrgId,
    emailAddress,
    userName,
    setDownloadingState,
) => {
    const onSuccess = () => {
        setDownloadingState && setDownloadingState(false);
        // @ts-ignore
        mixPanelTrack({
            id: investeeOrgId,
            trackUserObj: {
                email: emailAddress,
            },
            trackCategoryName: 'Download Accounting Statement',
        });
    };
    if (e) {
        e.preventDefault();
        e.stopPropagation();
    }
    if (!isDownloading) {
        setDownloadingState && setDownloadingState(true);
        downloadFileUsingFetch(
            `admin/investeeOrganization/${investeeOrgId}/calculate-tax-generate-excel`,
            'get',
            {},
            {
                'content-type': 'application/json',
            },
            `accounting_statement.zip`,
            onSuccess,
        );
    }
};
export const ifShowCounterSignButton = (deal) => {
    if (
        (deal?.investor_mfa_status === investor_mfa_status.investorSentForSigning ||
            deal?.investor_mfa_status === investor_mfa_status.investorSigning ||
            deal?.investor_mfa_status === investor_mfa_status.failed) &&
        deal?.mfa_status === 'signed' &&
        !isEmpty(deal?.mfa_invitation_url_investor ?? deal?.mfaInvitationUrlInvestor)
    ) {
        return true;
    }
    return false;
};

export function getExtensionFromUrl(url) {
    const lastDotIndex = url.lastIndexOf('.');
    if (lastDotIndex !== -1) {
        const extension = url.slice(lastDotIndex + 1);
        return extension;
    }
    return '';
}

//@ts-ignore
export const linkGenerator = async (urlid, bucket, investeeOrgId, fileName) => {
    let url;
    if (!urlid) return;
    if ((Number.isNaN(Number(urlid)) && urlid !== null) || undefined) {
        const commonStringInUrl = 'amazonaws.com/';
        const indexOfCommonString = urlid.indexOf(commonStringInUrl);
        const urlToSend = urlid.slice(indexOfCommonString + commonStringInUrl.length);
        url = urlToSend;
    } else if (urlid !== null || undefined) {
        url = urlid;
    }

    if (!url || !investeeOrgId) throw new Error();
    const res = await downloadFileHelper({ url, bucket, investeeOrgId });
    if (res?.status === API_STATUS.SUCCESS) {
        const SignedUrl = res?.data?.presignedUrl;
        let extension;
        if (Number.isNaN(Number(urlid))) {
            extension = getExtensionFromUrl(url);
        }

        return { SignedUrl, extension };
    } else {
        showNotification('Error', 'Please try again later');
    }
};

export const getDaysDiff = (date) => {
    const today = moment();
    const lastActivity = moment(date);
    const diff = today.diff(lastActivity, 'days');
    if (diff === 0) return 'Today';
    else if (diff === 1) return 'Yesterday';
    else return `${diff} days ago`;
};
const alphabetColorCodes = {
    A: '#9C2D23',
    B: '#A77D03',
    C: '#237037',
    D: '#2C59A3',
    E: '#681A75',
    F: '#007381',
    G: '#81AA90',
    H: '#A99023',
    I: '#026799',
    J: '#5F1871',
    K: '#00645B',
    L: '#A04161',
    M: '#5333AA',
    N: '#007F6E',
    O: '#AA5C43',
    P: '#AA8105',
    Q: '#40535D',
    R: '#899326',
    S: '#0271A3',
    T: '#696969',
    U: '#5D8231',
    V: '#4D6599',
    W: '#59929F',
    X: '#952BA7',
    Y: '#AA887F',
    Z: '#00645B',
    // Add more alphabets and color codes as desired
};

export function getRandomDarkColor(alphabet) {
    return alphabetColorCodes[alphabet?.toUpperCase()];
}

export const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
};

export const mapMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const amountToShowInLacCrNormal = (amount) => {
    if (amount >= 10000000) return (amount / 10000000).toFixed(2) + ' Cr';
    else if (amount >= 100000) return (amount / 100000).toFixed(2) + ' L';
    else return priceFormat(amount);
};

export const downloadFileFromMnemosyne = async (docId, setDownLoadStatus, openInNewTab) => {
    try {
        if (setDownLoadStatus) setDownLoadStatus(true);
        const response = await getPresignedUrl({ docId });
        if (response) {
            const url = response.uri;
            // download the file in new tab
            const link = document.createElement('a');
            link.href = url;
            if (openInNewTab) link.target = '_blank'; // for opening a new tab
            link.click();
        } else {
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, 'Not Able to fetch the file to show');
        }
    } finally {
        if (setDownLoadStatus) setDownLoadStatus(false);
    }
};
export const modifyFilterForInvesteePayments = (investeeOrgId, filters) => {
    const { selectorObjStatus } = filters;

    const filtersObj = {};
    filtersObj.filterBy = {};
    filtersObj.investee_organization_id = investeeOrgId;
    const statusArr = [...selectorObjStatus.value];
    const statusMainArr = [];
    statusArr.forEach((item) => {
        const { selected, label } = item;
        if (selected)
            statusMainArr.push(label === PAYMENT_STATUS.PAID ? PAYMENT_STATUS.COMPLETE : label);
    });
    if (!isEmpty(statusMainArr)) {
        filtersObj.filterBy[selectorObjStatus.keyName] = statusMainArr;
    }
    return filtersObj;
};

export function deepCopy(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        const copyArray = [];
        for (const item of obj) {
            copyArray.push(deepCopy(item));
        }
        return copyArray;
    }

    const copyObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            copyObj[key] = deepCopy(obj[key]);
        }
    }
    return copyObj;
}

//required for mnemosyne link generation
export const getcategoryIdForMnemosyne = (category) => {
    switch (category) {
        case UPLOAD_CATEGORIES.FD:
        case UPLOAD_CATEGORIES.OD:
            return 9;
        case UPLOAD_CATEGORIES.TDS:
            return 4;
        case UPLOAD_CATEGORIES.CP:
            return 22;
        case UPLOAD_CATEGORIES.CUSTOMER_SUPPORT_OTHER:
        case UPLOAD_CATEGORIES.CUSTOMER_SUPPORT:
        case UPLOAD_CATEGORIES.TOTAL_REPAYMENTS:
            return 23;
        case UPLOAD_CATEGORIES.BANK_STATEMENTS:
            return 2002;
        case UPLOAD_CATEGORIES.FINANCIAL:
            return 2003;
        case UPLOAD_CATEGORIES.MIS:
            return 2004;
        case UPLOAD_CATEGORIES.DEBT_SCHEDULE:
            return 2005;
        case UPLOAD_CATEGORIES.ADDITIONAL_DATA:
            return 2006;
        case UPLOAD_CATEGORIES.INVOICING:
            return 2007;
        case UPLOAD_CATEGORIES.ADDITIONAL_DOCS:
            return 2008;
        case UPLOAD_CATEGORIES.ADDITIONAL_REQUIRED_DOCUMENTS:
            return 2009;
        default:
            return 0;
    }
};

export const getFlowIdForMnemosyne = (path) => {
    if (path.includes(ADD_ON_LIMIT_CONST)) {
        return FLOW_ID.ADD_ON_LIMIT;
    } else if (path === ONBOARDING) {
        return FLOW_ID.ONBOARDING;
    } else return FLOW_ID.KYC;
};

export const getFileNameFromSignedUrl = (preSignedUrl, folderName) => {
    return decodeURIComponent(preSignedUrl)
        ?.split(folderName + '/')?.[1]
        .split('?')?.[0];
};

export const exportToCsv = ({ investorOrgId, investorOrganization, userName, currentTab }) => {
    const headers = { 'content-type': 'application/json' };
    let input = {
        investorOrganizationId: '',
        tableName: '',
    };
    let fileName = '';
    let tabName = '';
    switch (currentTab) {
        case PENDING_VERIFICATION_DEALS:
            input.investorOrganizationId = investorOrgId;
            input.tableName = EXPORT_CSV.PENDING_VERIFICATION;
            fileName =
                investorOrganization?.name +
                '_doc_verification_' +
                dayjs().format('DDMMMYYYY') +
                '.csv';
            tabName = PENDING_VERIFICATION_DEALS;
            break;
        case COMPLETED_VERIFICATION_DEALS:
            input.investorOrganizationId = investorOrgId;
            input.tableName = EXPORT_CSV.COMPLETED_VERIFICATION;
            fileName =
                investorOrganization?.name +
                '_doc_verification_' +
                dayjs().format('DDMMMYYYY') +
                '.csv';
            tabName = COMPLETED_VERIFICATION_DEALS;
            break;
        case NEW_DEALS:
            input.investorOrganizationId = investorOrgId;
            input.tableName = EXPORT_CSV.NEW_DEALS;
            fileName =
                investorOrganization?.name +
                '_doc_verification_' +
                dayjs().format('DDMMMYYYY') +
                '.csv';
            tabName = NEW_DEALS;
            break;
        case EVALUATED_DEALS:
            input.investorOrganizationId = investorOrgId;
            input.tableName = EXPORT_CSV.EVALUATED_DEALS;
            fileName =
                investorOrganization?.name +
                '_doc_verification_' +
                dayjs().format('DDMMMYYYY') +
                '.csv';
            tabName = EVALUATED_DEALS;
            break;
        default:
            break;
    }
    downloadFileUsingFetch('export-csv/v2', 'post', input, headers, fileName, () => {
        if (isEnvProd) {
            mixPanelTrackForExportCsv({
                id: investorOrgId,
                dataExportedFrom: tabName,
                userName,
            });
        }
    });
};

export const isSummonZeusInvestor = (email) => {
    return email?.includes('+investor@recur.club');
};

export const isZeusUser = (email) => {
    return email?.includes('+company@recur.club');
};

export const filterEPBankAccounts = (bankAccountsArray) => {
    const filteredArray = [];
    if (isNil(bankAccountsArray)) return filteredArray;
    for (const bankAccount of bankAccountsArray) {
        if (!isNil(bankAccount?.escrow_pay_bank_id)) {
            filteredArray.push(bankAccount);
        }
    }
    return filteredArray;
};

export function getBucketNameFromS3Url(url) {
    const urlParts = url.split('/');
    if (urlParts.length >= 3 && urlParts[2].endsWith('.amazonaws.com')) {
        const urlParts1 = urlParts[2].split('.');
        if (urlParts1.length > 0) {
            return urlParts1[0];
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export function getKeyFromS3Url(url) {
    const start = url.indexOf('amazonaws.com/') + 'amazonaws.com/'.length;
    const end = url.indexOf('?', start);
    return url.substring(start, end);
}

export const displayMobileViewFor = (pathname) => {
    const allowedRoutes = [
        INVESTEE_BASE_PATH,
        `/${INVESTEE_ROUTES.PUBLIC.SIGNUP}`,
        INVESTOR_ROUTES.PUBLIC.SIGNUP,
        INVESTEE_ROUTES.PUBLIC.FUNDING_CALCULATOR,
        INVESTEE_BASE_PATH.concat(
            INVESTOR_ROUTES.PUBLIC.SIGNUP.concat(`/${INVESTEE_ROUTES.PRIVATE.GET_STARTED}`),
        ),
        INVESTEE_BASE_PATH.concat(INVESTEE_ROUTES.PUBLIC.FORGOT_PASSWORD),
        INVESTEE_ROUTES.PUBLIC.CIBIL_VERIFICATION,
        INVESTEE_BASE_PATH.concat(INVESTEE_ROUTES.PUBLIC.OTP_SCREEN),
        INVESTEE_BASE_PATH.concat(
            INVESTEE_ROUTES.PRIVATE.BASE_PATH.concat(`/${INVESTEE_ROUTES.PRIVATE.ONBOARDING}`),
        ),
        INVESTEE_BASE_PATH.concat(
            INVESTEE_ROUTES.PRIVATE.BASE_PATH.concat(`/${INVESTEE_ROUTES.PRIVATE.DASHBOARD}`),
        ),
        INVESTEE_BASE_PATH.concat(
            INVESTEE_ROUTES.PRIVATE.BASE_PATH.concat(`/${INVESTEE_ROUTES.PRIVATE.GET_STARTED}`),
        ),
        INVESTEE_BASE_PATH.concat(
            INVESTEE_ROUTES.PRIVATE.BASE_PATH.concat(`/${INVESTEE_ROUTES.PRIVATE.RECUR_PRODUCTS}`),
        ),
    ];
    return !allowedRoutes.includes(pathname);
};

export const getCountOfMonthlyData = (monthlyData) => {
    if (!_.isEmpty(monthlyData)) {
        const currentMonth = moment();
        const last15Months = Array.from({ length: 15 }, (_, index) =>
            currentMonth.clone().subtract(index, 'months').format('MM-YYYY'),
        );
        const totalCount = last15Months.reduce((count, month) => {
            const status = monthlyData[month];
            if (status && status === 'Ingested') {
                count++;
            }
            return count;
        }, 0);
        return totalCount;
    } else {
        return 0;
    }
};

export const getMonthlyDataForOnboarding = (missingMonths, monthlyViewData) => {
    if (Array.isArray(missingMonths)) {
        const currentMonth = moment().format('YYYY-MM');
        // Function to convert month format
        const convertMonthFormat = (month) => {
            return moment(month, 'MMM_YYYY').format('MM-YYYY');
        };

        // Convert each month in the array
        const convertedMonths = missingMonths.map(convertMonthFormat);
        const monthlyUpload = {};

        // Function to get the label based on month and missingMonths array
        const getLabel = (month) => {
            if (convertedMonths.includes(month)) {
                return MONTHLY_VIEW_DATA_TYPES.NOT_UPLOADED;
            } else {
                return MONTHLY_VIEW_DATA_TYPES.INGESTED;
            }
        };

        // Generate the last 15 months keys in the "YYYY-MM" format
        for (let i = 0; i < 15; i++) {
            const monthKey = moment().subtract(i, 'months').format('MM-YYYY');
            monthlyUpload[monthKey] = getLabel(monthKey);
        }
        return monthlyUpload;
    } else if (monthlyViewData && !isEmpty(monthlyViewData)) {
        const newMonthlyViewData = { ...monthlyViewData };
        for (let i = 0; i < 15; i++) {
            const monthKey = moment().subtract(i, 'months').format('MM-YYYY');
            if (!newMonthlyViewData[monthKey]) {
                newMonthlyViewData[monthKey] = MONTHLY_VIEW_DATA_TYPES.NOT_UPLOADED;
            }
        }
        return newMonthlyViewData;
    } else {
        const monthlyUpload = {};
        // Generate the last 15 months keys in the "YYYY-MM" format
        for (let i = 0; i < 15; i++) {
            const monthKey = moment().subtract(i, 'months').format('MM-YYYY');
            monthlyUpload[monthKey] = MONTHLY_VIEW_DATA_TYPES.NOT_UPLOADED;
        }
        return monthlyUpload;
    }
};

export const validateBankDetails = (obj) => {
    const { bank_name, account_number, account_holder_name, ifsc } = obj;
    let isValid = true;
    let message = '';
    if (!bank_name.trim()) {
        message = 'One of the banks must be selected!';
        isValid = false;
    } else if (!account_holder_name.trim()) {
        message = 'Account holder name cannot be empty!';
        isValid = false;
    } else if (!account_holder_name.match(/^[A-Za-z0-9\s]+$/)) {
        message = 'Account holder name can only contain alphanumeric characters!';
        isValid = false;
    } else if (account_holder_name.length < 3) {
        message = 'Account holder name is too short!';
        isValid = false;
    } else if (!account_number.trim()) {
        message = 'Account number cannot be empty!';
        isValid = false;
    } else if (!account_number.match(/^[0-9A-Za-z\s]+$/)) {
        message = 'Account number is invalid!';
        isValid = false;
    } else if (!ifsc?.trim()) {
        message = 'IFSC code cannot be empty!';
        isValid = false;
    } else if (!ifsc.match(/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/)) {
        message = "IFSC code format isn't valid!";
        isValid = false;
    }
    return {
        isValid,
        message,
    };
};

export const getStatus = (pennyDropStatus, isPayoutAcc) => {
    // if (pennyDropStatus === PENNY_DROP_VERIFIED && isPayoutAcc) return 'Payout Account';
    if (pennyDropStatus === PENNY_DROP_VERIFIED) return 'VERIFIED';
    else return 'Verify Now';
};

export const getFullFileNameForOnBoardingAndAddOn = (input) => {
    let fullFileName = '';
    const timeStamp = moment().format('DDMMYYYY_HH_mm_ss');
    let extensionRequired = true;
    switch (input.category) {
        case UPLOAD_CATEGORIES.MIS:
            fullFileName = `${timeStamp}_ProvisionalFinancials`;
            break;
        case UPLOAD_CATEGORIES.INVOICING:
            fullFileName = `${timeStamp}_Invoicing`;
            break;
        case UPLOAD_CATEGORIES.FINANCIAL:
            fullFileName = `${timeStamp}_AuditedFinancials`;
            break;
        case UPLOAD_CATEGORIES.BANK_STATEMENTS:
            fullFileName = `${timeStamp}_BankStatement_${input.bankName
                ?.split(' ')
                ?.join('_')}_${input.bankAccountNumber?.slice(-4)}`;
            break;
        case UPLOAD_CATEGORIES.DEBT_SCHEDULE:
            fullFileName = `${timeStamp}_DebtSchedule`;
            break;
        case UPLOAD_CATEGORIES.ADDITIONAL_DATA:
            fullFileName = `${timeStamp}_${input.docName?.split(' ')?.join('_')}`;
            break;
        case UPLOAD_CATEGORIES.ADDITIONAL_REQUIRED_DOCUMENTS:
            fullFileName = `${timeStamp}_${input.additionalRequiredDocName?.split(' ')?.join('_')}`;
            break;
        case UPLOAD_CATEGORIES.OD:
            fullFileName = `${timeStamp}_Overdraft_${input.bankName?.split(' ')?.join('_')}_${
                input.odAmount
            }`;
            break;
        case UPLOAD_CATEGORIES.FD:
            fullFileName = `${timeStamp}_FixedDeposit_${input.bankName?.split(' ')?.join('_')}_${
                input.fdAmount
            }`;
            break;
        case UPLOAD_CATEGORIES.GST:
            fullFileName = `${timeStamp}_GST`;
            break;
        default:
            fullFileName = input.fullFileName;
            extensionRequired = false;
            break;
    }
    const file_extenstion = getExtensionFromUrl(input.fullFileName);
    if (file_extenstion && extensionRequired) {
        fullFileName = `${fullFileName}.${file_extenstion}`;
    }
    return fullFileName;
};

export const checkGenericMail = (email) => {
    let domain = email.split('@')[0];
    if (domain.includes('+')) {
        domain = domain.split('+')[0];
    }
    return genericEmails.includes(domain);
};

export const formatDateIsoStandard = (dateString) => {
    return moment(dateString).locale('en-IN').format('Do MMM YYYY');
};
